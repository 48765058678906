export const prod = {
    apiKey: "AIzaSyB9KsLaRtG9AAbV4cA09tYPt-gg4txWMgI",
    authDomain: "scrum-poker-table.firebaseapp.com",
    databaseURL: "https://scrum-poker-table.firebaseio.com",
    projectId: "scrum-poker-table",
    storageBucket: "scrum-poker-table.appspot.com",
    messagingSenderId: "13632343377",
    appId: "1:13632343377:web:c84345c4abd33d76cf9770",
    measurementId: "G-6ZW9HWG88F"
};

export const nonprod = {
    apiKey: "AIzaSyDLcJAQEF1Z3EfdG-mk0qVeCez9moBhqRA",
    authDomain: "scrum-poker-table-test.firebaseapp.com",
    databaseURL: "https://scrum-poker-table-test.firebaseio.com",
    projectId: "scrum-poker-table-test",
    storageBucket: "scrum-poker-table-test.appspot.com",
    messagingSenderId: "683154851104",
    appId: "1:683154851104:web:39a108879af5878a310345",
    measurementId: "G-PCH57NBEDK"
}
