import Vue from 'vue';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'
import 'firebase/compat/analytics';
import VueMaterial from 'vue-material';
import 'vue-material/dist/vue-material.css';
import VueSession from 'vue-session';
// import VueAnalytics from 'vue-analytics';
import App from './App.vue';
import router from './router';
import { prod, nonprod } from './config/config';

const env = (location.hostname === 'localhost' ||
  location.hostname === 'scrum-poker-table-test.web.app')
  ? 'nonprod' : 'prod';

Vue.use(VueMaterial);
Vue.use(VueSession);
// if(env === 'prod') {
//   Vue.use(VueAnalytics, {
//     id: 'UA-132326565-1',
//     router,
//   });
// }

let app;
const config = env === 'nonprod' ? nonprod : prod;
firebase.initializeApp(config);
firebase.analytics();
firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      render: h => h(App),
      router,
    }).$mount('#app');
  }
});
