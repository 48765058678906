<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style lang="scss">
@import "~vue-material/dist/theme/engine"; // Import the theme engine

@include md-register-theme("default", (
  primary: #046152, // The primary color of your brand
  accent: #bda770 // The secondary color of your brand
));

@import "~vue-material/dist/theme/all"; // Apply the theme
html {
  /* background-image: linear-gradient(to bottom right, #1d201e, #313531); */
  height: 100%;
}
body {
  height: 100%;
}
#app {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  /* padding-top: 60px;
  padding-bottom: 60px; */
}
</style>
