import Vue from 'vue';
import VueRouter from 'vue-router';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
// import Room from '../containers/Room.vue';
// import Table from '../containers/Table.vue';

const Room = () => import('../containers/Room.vue');
const Table = () => import('../containers/Table.vue');

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '*', component: Room },
    { path: '/', component: Room },
    { path: '/room', name: 'Room', component: Room },
    { path: '/table/:id', name: 'Table', component: Table, props: true, meta: { requiresAuth: true } },
  ],
});

router.beforeEach((to, from, next) => {
  const currentUser = firebase.auth().currentUser;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (requiresAuth && !currentUser) {
    next({ name: 'Room' });
  } else {
    next();
  }
});

export default router;
